import { Divider, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import like from '../../../../../assets/images/blue-material-thumb-up.svg';
import unLike from '../../../../../assets/images/comment.svg';
import comment from '../../../../../assets/images/postcomment.svg';
import SavedIcon from '../../../../../layout/home/images/SavedIcon';
import { getComments, updateActivity } from '../../../../features/socialAppSlice';
import CommentComponent from './CommentComponent';
import './postFooter.scss';

const PostFooter = ({ postDetails, setPostData, isUserPost, setAllPosts, allPostsPage = false }) => {
  const userId = useSelector((state) => state.user?.user?.id);
  const [isVisiable, setIsVisiable] = useState(false);
  const dispatch = useDispatch();

  const handleLike = () => {
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'likes' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isLiked: true,
            likes: (prev?.likes || 0) + 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isLiked: true, likes: (elem?.likes || 0) + 1 };
            }
            return elem;
          }),
        }));
      }
    });

    // dispatch(getComments({ userId: userId, postId: postDetails?._id }));
  };

  const handleUnLike = () => {
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'unlikes' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isLiked: false,
            likes: (prev?.likes || 0) - 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isLiked: false, likes: elem?.likes - 1 };
            }
            return elem;
          }),
        }));
      }
    });

    // dispatch(getComments({ userId: userId, postId: postDetails?._id }));
  };

  const handleSave = () => {
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'saves' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isSaved: true,
            saves: (prev?.saves || 0) + 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id == postDetails?._id) {
              elem = { ...elem, isSaved: true, saves: (elem?.saves || 0) + 1 };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleUnSave = () => {
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'unsaves' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isSaved: false,
            saves: (prev?.saves || 0) - 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id == postDetails?._id) {
              elem = { ...elem, isSaved: false, saves: elem?.saves - 1 };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleCancel = () => {
    setIsVisiable(false);
  };

  const showModal = () => {
    setIsVisiable(!isVisiable);
    if (!isVisiable) {
      dispatch(getComments({ userId: userId, postId: postDetails?._id }));
    }
  };
  return (
    <div className="d-flex d-column" style={{ marginLeft: '410px', marginTop: '-95px' }}>
      <div className="d-flex jc-between p-5">
        <span className="d-flex jc-between g-20">
          <span onClick={postDetails?.isLiked ? handleUnLike : handleLike} style={{ cursor: 'pointer' }}>
            <img src={postDetails?.isLiked ? like : unLike} alt={postDetails?.isLiked ? 'like' : 'unLike'} />
          </span>
          <span
            onClick={() => {
              if (allPostsPage) showModal();
            }}
            style={allPostsPage ? { cursor: 'pointer' } : { cursor: 'auto' }}
          >
            <img src={comment} alt="comment" />
          </span>
          <Modal
            styles={{
              mask: {
                backdropFilter: 'blur(6px)',
              },
            }}
            centered
            title={null}
            open={isVisiable}
            onCancel={handleCancel}
            footer={null}
            width={820}
          >
            <CommentComponent
              setAllPosts={setAllPosts}
              userId={userId}
              postId={postDetails?._id}
              postDetails={postDetails}
            />
          </Modal>
        </span>
        <span onClick={postDetails?.isSaved ? handleUnSave : handleSave} style={{ cursor: 'pointer' }}>
        <SavedIcon strokeColor={postDetails?.isSaved ? '#1677ff' : 'black'} />
        </span>
      </div>
      <Divider className="footerDivider" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          color: '#cbcbcb',
          marginTop: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
          }}
        >
          <span>{postDetails.likes || 0} Likes</span>
          <span>
            <li>{postDetails.comments || 0} Comments</li>
          </span>
        </div>
        <span>{postDetails.saves || 0} Saves</span>
      </div>

    </div>
  );
};

export default PostFooter;
