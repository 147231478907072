import { Card, Col, Row, Rate, List, Divider, Button, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, truncate } from 'lodash';
import { nanoid } from '@reduxjs/toolkit';
import ProspectImage from '../../../../components/prospectImage/ProspectImage';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getSearchResults, getPropertiesFromOrganization } from '../../../../features/searchSlice';
import bookmark from '../../../../../assets/images/bookmark.svg';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import { useNavigate } from 'react-router';

const Organization = ({ organizationsData, onDeleteCard, onSaveCard }) => {
  const [openOverflowMenu, setOpenOverflowMenu] = useState(false);
  const { searchQuery, pagination, hasMoreData } = useSelector((store) => store.search);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const getLockedArray = (org) => {
    return ['directorInfo', 'propertyEngagement'];
  };

  const loadMoreData = () => {
    dispatch(
      getSearchResults({
        body: {
          query: searchQuery?.body?.query,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    );
  };

  return isEmpty(organizationsData) ? (
    <></>
  ) : (
    <div>
      {!isEmpty(organizationsData) && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', height: '30px', gap: '30px' }}>
          {/* <div style={{ fontWeight: '600', color: '#707070' }}>Search Results</div> */}
          <div style={{ color: '#9f9f9f', marginLeft: '126px' }}>
            Your search result for company as{' '}
            <i style={{ color: '#48a4fc', fontWeight: '600' }}>{searchQuery.body.query}</i> and similar companies
          </div>
        </div>
      )}
      <div id="columnTwoDiv" style={{ height: '80vh', overflow: 'auto' }}>
        <InfiniteScroll
          dataLength={organizationsData?.length}
          next={() => {
            loadMoreData();
          }}
          loader={
            <div className="d-flex jc-center">
              <Spin size={64} />
            </div>
          }
          hasMore={hasMoreData}
          endMessage={<Divider plain>It is all, nothing more</Divider>}
          scrollableTarget="columnTwoDiv"
        >
          <List
            dataSource={organizationsData}
            renderItem={(org) => (
              <List.Item key={nanoid()} style={{ borderBlockEnd: 'none' }}>
                <div key={org} className="organizationListContainer__div cursor-pointer">
                  <Card
                    className="organizationListContainer__div__card"
                    key={nanoid()}
                    onClick={() => {
                      dispatch(getPropertiesFromOrganization(org?._id)).then((res) => {
                        navigateTo(`/leadgen/connectedDetails/${res?.payload?.[0]?._id}`);
                      });
                    }}
                  >
                    <Row className="organizationListContainer__div__card__row">
                      <Col span={24} className="organizationListContainer__div__card__row__col">
                        <Row className="organizationListContainer__div__card__row__col__row" style={{ gap: '12px' }}>
                          <Col span={6} className="organizationListContainer__div__card__row__col__row__col">
                            {org?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage ? (
                              <ProspectImage
                                imageUrl={org?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage}
                                size={60}
                                imgClassName="organizationListContainer__div__card__row__col__row__col__img"
                              />
                            ) : (
                              <div
                                style={{
                                  border: '1px solid black',
                                  width: '60px',
                                  borderRadius: '50%',
                                  marginBottom: '17px',
                                }}
                              >
                                <LottieAnimation height={60} width={60} animationData={animationData} loop={true} />
                              </div>
                            )}
                          </Col>
                          <Col span={16} className="organizationListContainer__div__card__row__col__row__col1">
                            <h5
                              className="organizationListContainer__div__card__row__col__row__col1__h5"
                              // onClick={(e) => {
                              //   e.stopPropagation();
                              //   navigateTo(`/leadgen/organizationDetails/${org?._id}`);
                              // }}
                            >
                              <u style={{ color: '#0081FC', fontSize: '0.8rem' }}>{org?.companyName}</u>
                            </h5>
                            <p className="organizationListContainer__div__card__row__col__row__col1__para">
                              Public Limited Company
                            </p>

                            <Rate allowHalf disabled defaultValue={org?.average_rating || 4} />
                          </Col>
                          <Col span={2} className="organizationListContainer__div__card__row__col__row__col2">
                            {!org?.isSaved ? (
                              <img
                                src={bookmark}
                                alt="bookmark"
                                style={{
                                  visibility: !org?.isResourceLocked ? 'hidden' : 'visible',
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onSaveCard(
                                    org?._id,
                                    org?.companyName,
                                    'organization',
                                    getLockedArray(org),
                                    'commercial',
                                  );
                                }}
                              />
                            ) : (
                              <img
                                src={bookmark_selected}
                                alt="bookmark_selected"
                                style={{ visibility: !org?.isResourceLocked ? 'hidden' : 'visible', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onDeleteCard(
                                    org?._id,
                                    org?.companyName,
                                    'organization',
                                    getLockedArray(org),
                                    'commercial',
                                  );
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row className="organizationListContainer__div__card__row__col__row1__col__row">
                          <Col
                            span={14}
                            className="organizationListContainer__div__card__row__col__row1__col__row__col"
                          >
                            <Row
                              className="organizationListContainer__div__card__row__col__row1__col__row__col__row"
                              style={{ display: 'block' }}
                            >
                              <h5 className="organizationListContainer__div__card__row__col__row1__col__row__col__h5">
                                Registered Address:
                              </h5>
                              <p className="organizationListContainer__div__card__row__col__row1__col__row__col1__para">
                                {populateAddressInfo(org?.companyAddresses?.[0])}
                              </p>
                            </Row>
                            <div
                              style={{
                                height: '55px',
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '125px',
                                marginTop:
                                  populateAddressInfo(org?.companyAddresses?.[0]) != 'Awaited' ? '-80px' : '-65px',
                              }}
                            >
                              <Divider type="vertical" style={{ height: '100%', top: '40%', left: ' 50%' }} />
                            </div>
                          </Col>

                          <Col
                            style={{ marginTop: '-10px' }}
                            span={10}
                            className="organizationListContainer__div__card__row__col__row1__col1"
                          >
                            <Row className="organizationListContainer__div__card__row__col__row1__col1__row">
                              <h5 className="organizationListContainer__div__card__row__col__row1__col1__row__h5">
                                Website:
                              </h5>
                              <span className="organizationListContainer__div__card__row__col__row1__col1__row__para">
                                {org?.otherCompanyInfo?.websiteLink ? (
                                  <Tooltip
                                    title={org?.otherCompanyInfo?.websiteLink}
                                    overlayStyle={{ zIndex: 1000000000 }}
                                  >
                                    {truncate(org?.otherCompanyInfo?.websiteLink, { length: 10 })}
                                  </Tooltip>
                                ) : (
                                  'Awaited'
                                )}
                              </span>
                            </Row>
                            <Row className="organizationListContainer__div__card__row__col__row1__col1__row">
                              <h5 className="organizationListContainer__div__card__row__col__row1__col1__row__h5">
                                Industry:
                              </h5>
                              <p className="organizationListContainer__div__card__row__col__row1__col1__row__para">
                                {org?.industryType ?? 'Awaited'}
                              </p>
                            </Row>
                            <Row className="organizationListContainer__div__card__row__col__row1__col1__row">
                              <h5 className="organizationListContainer__div__card__row__col__row1__col1__row__h5">
                                Strength:
                              </h5>
                              <p className="organizationListContainer__div__card__row__col__row1__col1__row__para">
                                {org?.otherCompanyInfo?.maximumNumberOfMembers ?? 'Awaited'}
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Divider style={{ marginLeft: '0px' }} />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flex: 'auto',
                          alignSelf: 'center',
                          height: '10px',
                        }}
                      >
                        <h3
                          style={{
                            fontSize: '0.7rem',
                            marginLeft: '17px',
                            marginTop: '-6px',
                            color: '#0080FC',
                            alignSelf: 'center',
                            textAlign: 'center',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateTo(`/leadgen/organizationDetails/${org?._id}`);
                          }}
                        >
                          View More Details
                        </h3>
                      </div>
                      <Col span={2} className="organizationListContainer__div__card__row__col1">
                        <div
                          className="organizationListContainer__div__card__row__col1__ellipsisContainer"
                          onClick={() => setOpenOverflowMenu(!openOverflowMenu)}
                        ></div>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Organization;
