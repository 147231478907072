import React from 'react';
import './employeeDetails.scss';
import connectBG from '../../../../../assets/images/connectBG.png';
import { isEmpty } from 'lodash';
import ConnectDetails from './ConnectDetails';

const EmployeesDetails = ({ connectsData, loader }) => {
  return (
    <div  className="connect_container  d-flex  g-10 d-column">
      <span className="font16 fontLight">Employee of Selected Company</span>
      <div className={`employeeDetails ${loader && 'border-light-2'}`}>
        {/* {isEmpty(connectsData) && !loader ? (
          <img src={connectBG} />
        ) : (
          <ConnectDetails loader={loader} connectsData={connectsData} />
        )} */}
        <img src={connectBG} />
      </div>
    </div>
  );
};

export default EmployeesDetails;
