import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { socialAppFollowUser, socialAppUnFollowUser } from '../../../../features/socialAppSlice';
import NoPost from '../../../myAccount/Posts/NoPost';
import PostHeader from '../postHeader/PostHeader';
import GenericPost from './genericPost/GenericPost';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PostFooter from '../postFooter/PostFooter';
import './AllPosts.scss';
import ReportCardPost from './reportCardPost/ReportCardPost';

const AllPosts = ({ setAllposts, handleScroll, allPosts }) => {
  // hooks
  const dispatch = useDispatch();
  const postTypes = {
    generic_card: GenericPost,
    report_card: ReportCardPost,
  };
  const handleFollow = (userId) => {
    return dispatch(
      socialAppFollowUser({
        userToFollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        setAllposts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?.ownerId === userId) {
              elem = { ...elem, isFollowed: true };
            }
            return elem;
          }),
        }));
      }
    });
  };
  const handleUnFollow = (userId) => {
    return dispatch(
      socialAppUnFollowUser({
        userToUnfollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        setAllposts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?.ownerId === userId) {
              elem = { ...elem, isFollowed: false };
            }
            return elem;
          }),
        }));
      }
    });
  };

  return (
    <div id="scrolldiv" className="postContainer">
      {allPosts?.posts?.length > 0 ? (
        <InfiniteScroll
          dataLength={200}
          next={handleScroll}
          hasMore={(allPosts?.posts?.length || 0) < (allPosts?.totalRecords || 0)}
          loader={
            <span>
              <Spin className="w-100 d-flex jc-center" indicator={<LoadingOutlined />} />
            </span>
          }
          scrollableTarget="postContainer"
        >
          {allPosts?.posts?.map((post) => {
            const CurrentPost = postTypes?.[post?.type];
            return (
              <div key={post._id} className="allPostContainer">
                <PostHeader handleFollow={handleFollow} handleUnFollow={handleUnFollow} postDetails={post} />
                {CurrentPost && <CurrentPost postDetails={post} />}
                {/* <PostFooter postDetails={post} /> */}
                {
                  <PostFooter
                    setAllPosts={setAllposts}
                    handleFollow={handleFollow}
                    postDetails={post}
                    allPostsPage={true}
                  />
                }
              </div>
            );
          })}
        </InfiniteScroll>
      ) : (
        <NoPost />
      )}
    </div>
  );
};

export default AllPosts;
