import Text from 'antd/es/typography/Text';
import DOMPurify from 'dompurify';
import React from 'react';
import CommonButton from '../../../common/CommonButton';

import { DownloadOutlined } from '@ant-design/icons';
import './reportCardPost.scss';

const ReportCardPost = ({ postDetails }) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  const sanitizer = DOMPurify.sanitize;

  return (
    <>
      <div className="generic_post_content ">
        <div className="generic_post_images">
          <div
            style={{
              background: `
            linear-gradient(0deg, rgba(0, 0, 0, 6.5), rgba(255, 255, 255, 0.3)), url(${
              postDetails.imageUrls?.[0]?.url || defaultImage
            })
`,
            }}
            className="report-carousel-image"
          >
            <span className="postImageTitle">{postDetails?.title}</span>
            {postDetails?.postDetails?.pdfUrl && (
              <a
                target="_blank"
                href={postDetails?.postDetails?.pdfUrl}
                download="report-card"
                className="postImageFooter"
              >
                <DownloadOutlined style={{ fontSize: 16 }} />
                Download full Report
              </a>
            )}
          </div>
          {/* // <img src={defaultImage} alt="Default Post" className="carousel-image" /> */}
          {/* {postDetails?.imageUrls && postDetails.imageUrls?.length > 0 ? (
            <ImageCarousel
              className="carousel-image"
              imageUrls={postDetails.imageUrls?.map((elem) => elem?.url) || []}
            />
          ) : (
            <img src={defaultImage} alt="Default Post" className="carousel-image" />
          )} */}
        </div>
        <div
          style={{
            display: 'grid',
            gridAutoRows: 'max-content',
            gap: ' 6px',
            flex: '1',
            overflow: 'scroll',
            marginTop: '24px',
            paddingBottom: '24px',
          }}
        >
          <Text className="font14 fontDark font700">{postDetails?.title}</Text>
          <div
            className="font14 postBody fontExtraLight"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.synopsys) }}
          ></div>
          <div
            className="font14 postBody fontExtraLight"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
          ></div>
          <span style={{ display: 'flex', flexWrap: 'wrap' }} className="d-flex font500 fontExtraLight g-5">
            {postDetails?.tags?.map((elem, index) => (
              <span style={{ flex: 'none' }} key={index} className=" font14 font500 fontExtraLight g-15">
                #{elem}
              </span>
            ))}
          </span>

          {postDetails?.CTA && (
            <CommonButton style={{ width: '90px' }} target="_blank" href={postDetails?.CTA?.link}>
              {postDetails?.CTA?.name}
            </CommonButton>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportCardPost;
