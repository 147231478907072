import React, { useEffect, useState } from 'react';
import { Form, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { userOnboardingGetPreferencesList } from '../../../features/userOnboardingSlice';
import PreselectedTags from '../Offerings/UserTypeOfferingsLayout/common/PreselectedTags';
import DropDown from './DropDown';
import './Preference.scss';

const Preference = ({ _userPreferenceData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);

  // State for user preferences
  const [userSellsTags, setUserSellsTags] = useState([]);
  const [userSellOptions, setUserSellOptions] = useState([]);
  const [userAudienceOptions, setUserAudienceOptions] = useState([]);
  const [userBuyOptions, setUserBuyOptions] = useState([]);
  const [userTargetAudienceTags, setUserTargetAudienceTags] = useState([]);
  const [userWouldBuyTag, setUserWouldBuyTag] = useState([]);

  // Fetch options from API and set state
  const fetchOptions = (type, setterFunc) => {
    dispatch(userOnboardingGetPreferencesList(type)).then((res) => {
      const options = res?.payload?.data?.response?.data;
      if (options) {
        setterFunc(options.map((elem) => ({ label: elem?.name, key: elem?.name, value: elem?.name })));
      }
    });
  };

  // Set initial values when _userPreferenceData changes
  useEffect(() => {
    if (!isEmpty(_userPreferenceData)) {
      setUserSellsTags(_userPreferenceData.userSells ? _userPreferenceData.userSells : []);
      setUserTargetAudienceTags(_userPreferenceData.userTargetAudience ? _userPreferenceData.userTargetAudience : []);
      setUserWouldBuyTag(_userPreferenceData.userWouldBuy ? _userPreferenceData.userWouldBuy : []);
    }
  }, [_userPreferenceData]);

  return (
    <section className="preferences">
      {/* User Sells */}
      <div className="preferences-container">
        <Space direction="vertical" className="preferences-container__space">
          <Form.Item
            label={`Services that ${user.name} sells`}
            name="preference_userSells"
            className="preferences-container__space--item"
            rules={[
              { required: true, message: 'Please select what you would like to sell' },
            ]}
          >
            <DropDown
              name="preference_userSells"
              options={userSellOptions}
              allTags={userSellsTags}
              setterFunc={setUserSellsTags}
              fetchOptions={() => fetchOptions('userSells', setUserSellOptions)}
            />
          </Form.Item>
          <Form.Item
            name="preference_userSells"
                  style={{ margin: 0 }}
                  className={` ${
                    userSellsTags && userSellsTags?.length > 0
                      ? 'preferences-container__space--item'
                      : 'preferences-container__space--noData'
                  } `}
                >
                  {userSellsTags && userSellsTags?.length > 0 && (
                    <PreselectedTags setTagData={setUserSellsTags} tagsData={userSellsTags} />
                  )}
          </Form.Item>

          <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
        </Space>
      </div>

      {/* User Target Audience */}
      <div className="preferences-container">
        <Space direction="vertical" className="preferences-container__space">
          <Form.Item
            label={`${user.name}/BI target Audience`}
            name="preference_userTargetAudience"
            className="preferences-container__space--item"
            rules={[
              { required: true, message: 'Please select audience what you would like to target' },
            ]}
          >
            <DropDown
              name="preference_userTargetAudience"
              options={userAudienceOptions}
              allTags={userTargetAudienceTags}
              setterFunc={setUserTargetAudienceTags}
              fetchOptions={() => fetchOptions('userTargetAudience', setUserAudienceOptions)}
            />
          </Form.Item>
          <Form.Item
            name="preference_userTargetAudience"
            style={{ margin: 0 }}
            className={` ${
              userTargetAudienceTags && userTargetAudienceTags?.length > 0
                      ? 'preferences-container__space--item'
                      : 'preferences-container__space--noData'
                  } `}
            >
            {userTargetAudienceTags && userTargetAudienceTags?.length > 0 && (
                    <PreselectedTags n setTagData={setUserTargetAudienceTags} tagsData={userTargetAudienceTags} />
            )}
          </Form.Item>
          <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
        </Space>
      </div>

      {/* User Would Like to Buy */}
      <div className="preferences-container">
        <Space direction="vertical" className="preferences-container__space">
          <Form.Item
            label={`Services that ${user.name}/BI would like to buy`}
            name="preference_userWouldBuy"
            className="preferences-container__space--item"
            rules={[
              { required: true, message: 'Please select what you would like to buy' },
            ]}
          >
            <DropDown
              name="preference_userWouldBuy"
              options={userBuyOptions}
              allTags={userWouldBuyTag}
              setterFunc={setUserWouldBuyTag}
              fetchOptions={() => fetchOptions('userWouldBuy', setUserBuyOptions)}
            />
          </Form.Item>
          <Form.Item
            name="preference_userWouldBuy"
            style={{ margin: 0 }}
            className={` ${
              userWouldBuyTag && userWouldBuyTag?.length > 0
                      ? 'preferences-container__space--item'
                      : 'preferences-container__space--noData'
                  } `}
          >
            {userWouldBuyTag && userWouldBuyTag?.length > 0 && (
              <PreselectedTags setTagData={setUserWouldBuyTag} tagsData={userWouldBuyTag} />
            )}
          </Form.Item>

          <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
        </Space>
      </div>
    </section>
  );
};

export default Preference;
