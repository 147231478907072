import { nanoid } from '@reduxjs/toolkit';
import { Card, Col, Divider, List, Rate, Row, Spin, Tooltip, Typography } from 'antd';
import { isEmpty, truncate } from 'lodash';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router';
import aminities from '../../../../../assets/images/Icon feather-home.svg';
import location from '../../../../../assets/images/Icon metro-location.svg';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import AmenitiesIconEnum from '../../../../../utils/referenceData/search/amenitiesIconEnum';
import { getSearchResults } from '../../../../features/searchSlice';
import '../../../searchPage/searchPage.scss';
import SelectedPropertyDetailsSkeleton from '../../../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetailsSkeleton';
import CustomSkeleton from '../../../../../universal/components/skeleton/CustomSkeleton';
import { CiCircleFilled } from '@ant-design/icons';

const Property = ({ propertyData, onSaveCard, onDeleteCard }) => {
  const { searchQuery, pagination, hasMoreData, searchResultLoader } = useSelector((store) => store.search);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const loadMoreData = () => {
    dispatch(
      getSearchResults({
        body: {
          query: searchQuery?.body?.query,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    );
  };

  const navigateToPropertyDetail = (e, id) => {
    e.stopPropagation();
    navigateTo(`/leadGen/propertyDetails/${id}`, { replace: true });
  };
  return (
    <div>
      {!isEmpty(propertyData) && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', height: '30px', gap: '30px' }}>
          {/* <div style={{ fontWeight: '600', color: '#707070' }}>Search Results</div> */}
          <div style={{ color: '#9f9f9f', marginLeft: '126px' }}>
            Your search result for Commercial property as{' '}
            <i style={{ color: '#48a4fc', fontWeight: '600' }}>{searchQuery?.body?.query}</i> and similar properties
          </div>
        </div>
      )}
      {/* ----------search card skeleton------- */}

      {isEmpty(propertyData) && searchResultLoader && (
        <div className="d-flex  flex-wrap g-10 jc-between">
          {[...new Array(20)]?.map(() => (
            <SelectedPropertyDetailsSkeleton className="searchCardSkeleton" key={nanoid()} />
          ))}
        </div>
      )}
      {/* ----------search card skeleton------- */}
      {!isEmpty(propertyData) && (
        <div id="columnOneDiv" style={{ height: '80vh', overflow: 'auto', paddingBottom: '100px' }}>
          <InfiniteScroll
            dataLength={propertyData?.length}
            next={() => {
              loadMoreData();
            }}
            loader={
              <div className="d-flex jc-center">
                <Spin size={64} />
              </div>
            }
            hasMore={hasMoreData}
            endMessage={<Divider plain>It is all, nothing more </Divider>}
            scrollableTarget="columnOneDiv"
          >
            <List
              dataSource={propertyData}
              renderItem={(propertyItem) => (
                <List.Item key={nanoid()} style={{ borderBlockEnd: 'none' }}>
                  <div key={propertyItem?._id} className="propertyListContainer__div  cursor-pointer">
                    <Card
                      onClick={() => {
                        navigateTo(`/leadgen/connectedDetails/${propertyItem?._id}`);
                      }}
                      className="propertyListContainer__div__card"
                    >
                      <Row className="inside_row">
                        <Col
                          span={7}
                          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          className="propertyListContainer__div__card__row__col__row__col"
                        >
                          {propertyItem?.assetsInfo?.[0]?.featuredImage ? (
                            <img
                              src={propertyItem.assetsInfo[0].featuredImage}
                              alt="Property Img"
                              className="propertyListContainer__div__card__row__col__row__col__img"
                            />
                          ) : (
                            <LottieAnimation height={70} width={100} animationData={animationData} loop={true} />
                          )}
                        </Col>
                        <Col span={16} className="d-flex d-column jc-between">
                          <div>
                            <div className="d-flex jc-between a-center mt-10 ">
                              <h5
                                // onClick={(e) => navigateToPropertyDetail(e, propertyItem?._id)}
                                style={{ color: '#0081FC' }}
                                className="text-underline propertyListContainer__div__card__row__col__row__col1__h5"
                              >
                                {propertyItem?.buildingName}
                              </h5>
                              <div>
                                {!propertyItem?.isSaved && (
                                  <img
                                    src={bookmark}
                                    alt="bookmark"
                                    style={{
                                      visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                      cursor: 'pointer',
                                      height: '25px',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onSaveCard(
                                        propertyItem?._id,
                                        propertyItem?.buildingName,
                                        'property',
                                        ['representativeInfo'],
                                        'commercial',
                                      );
                                    }}
                                  />
                                )}
                                {propertyItem?.isSaved && (
                                  <img
                                    src={bookmark_selected}
                                    alt="bookmark_selected"
                                    style={{
                                      visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                      cursor: 'pointer',
                                      height: '25px',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteCard(
                                        propertyItem?._id,
                                        propertyItem?.buildingName,
                                        'property',
                                        ['representativeInfo'],
                                        'commercial',
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              className="propertyListContainer__div__card__row__col__row__col1__para"
                              style={{ color: '#313131' }}
                            >
                              <span>{propertyItem?.addressInfo?.[0]?.locality}</span>,
                              {propertyItem?.addressInfo?.[0]?.city}
                            </div>
                            <Rate
                              allowHalf
                              disabled
                              defaultValue={
                                !isNaN(Number(propertyItem?.averageRating)) ? Number(propertyItem?.averageRating) : 0
                              }
                            />
                            <div className="d-flex  d-column g-5 mt-10">
                              <div className="d-flex g-5">
                                <img
                                  src={location}
                                  style={{ marginTop: 3 }}
                                  alt="location"
                                  className="propertyListContainer__div__card__row1__col1__row__col__img"
                                />
                                <span className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                  <Tooltip
                                    title={populateAddressInfo(propertyItem?.addressInfo?.[0])}
                                    overlayStyle={{ zIndex: 1000000000 }}
                                  >
                                    {truncate(populateAddressInfo(propertyItem?.addressInfo?.[0]), { length: 100 })}
                                  </Tooltip>
                                </span>
                              </div>
                              <div className="d-flex g-5 a-center">
                                <img
                                  src={aminities}
                                  alt="aminities"
                                  className="propertyListContainer__div__card__row1__col1__row__col__img"
                                />
                                <p className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                  <span className="bold">Amenities: </span> Grade of building: A
                                </p>
                              </div>
                              <div
                                style={{ display: 'inline-flex', justifyContent: 'space-between', marginTop: '10px' }}
                              >
                                {propertyItem?.amenities && (
                                  <div
                                    className="propertyListContainer__div__card__row1__col1__div"
                                    style={{
                                      maxHeight: '200px',
                                      display: 'flex',
                                      width:
                                        propertyItem?.amenities && propertyItem?.amenities?.length < 4
                                          ? '80%'
                                          : '187px',
                                    }}
                                  >
                                    {propertyItem?.amenities &&
                                      propertyItem?.amenities?.length > 0 &&
                                      propertyItem?.amenities?.slice(0, 4)?.map((item, index) => {
                                        return (
                                          <Col
                                            span={
                                              propertyItem?.amenities && propertyItem?.amenities?.length < 4 ? 6 : 4
                                            }
                                            className="propertyListContainer__div__card__row1__col1__div__col"
                                            style={{ flex: index === 0 ? 'initial' : 'auto' }}
                                          >
                                            {isEmpty(AmenitiesIconEnum?.[item?.toLowerCase()]) && (
                                              <img
                                                title={'DEFAULT'}
                                                src={AmenitiesIconEnum?.['DEFAULT']}
                                                className="propertyListContainer__div__card__row1__col1__div__col__img"
                                              />
                                            )}
                                            {!isEmpty(AmenitiesIconEnum?.[item?.toLowerCase()]) && (
                                              <img
                                                title={item}
                                                src={AmenitiesIconEnum?.[item?.toLowerCase()]}
                                                className="propertyListContainer__div__card__row1__col1__div__col__img"
                                              />
                                            )}
                                            {/* <p
                                              className="propertyListContainer__div__card__row1__col1__div__col__para"
                                              title={item}
                                              style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '300px', // Adjust the width as needed
                                              }}
                                            >
                                              {item}
                                            </p> */}
                                          </Col>
                                        );
                                      })}
                                  </div>
                                )}
                                {propertyItem?.amenities?.length > 4 ? (
                                  <p
                                    className="propertyListContainer__div__card__row1__col1__div__col__para"
                                    style={{ verticalAlign: 'top', marginTop: '5px', fontWeight: 'bold' }}
                                  >
                                    {`${propertyItem?.amenities?.length - 4} more...`}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="bottomRow d-flex jc-between">
                            <h3
                              onClick={(e) => navigateToPropertyDetail(e, propertyItem?._id)}
                              className="propertyListContainer__div__card__row1__col1__row__col1__para"
                              style={{ color: '#0080FC', cursor: 'pointer', margin: 'auto', fontSize: '0.7rem' }}
                            >
                              View All Transaction
                            </h3>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default Property;
