import { Col, Rate, Row, Tooltip } from 'antd';
import { isEmpty, truncate } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router';
import aminities from '../../../../../assets/images/Icon feather-home.svg';
import location from '../../../../../assets/images/Icon metro-location.svg';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import AmenitiesIconEnum from '../../../../../utils/referenceData/search/amenitiesIconEnum';
import './propertyDetails.scss';

const SelectedPropertyDetail = ({ propertyItem, onSaveCard, onDeleteCard }) => {
  const navigateTo = useNavigate();

  const navigateToPropertyDetail = (e) => {
    e.stopPropagation();
    navigateTo(`/leadgen/propertyDetails/${propertyItem?._id}`);
  };
  return (
    <div key={propertyItem?._id} className=" cursor-pointer">
      <div className="propertyCard">
        <Row className="inside_row">
          <Col
            span={7}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            className="propertyListContainer__div__card__row__col__row__col"
          >
            {propertyItem?.featuredImage ? (
              <img
                src={propertyItem?.featuredImage}
                alt="Property Img"
                className="propertyListContainer__div__card__row__col__row__col__img"
              />
            ) : (
              <LottieAnimation height={70} width={100} animationData={animationData} loop={true} />
            )}
          </Col>
          <Col span={16} className="d-flex d-column jc-between">
            <div>
              <div className="d-flex jc-between a-center mt-10 ">
                <h5
                  style={{ color: '#0081FC' }}
                  className="text-underline propertyListContainer__div__card__row__col__row__col1__h5"
                  onClick={navigateToPropertyDetail}
                >
                  {propertyItem?.buildingName}
                </h5>
                <div>
                  {!propertyItem?.isSaved && (
                    <img
                      src={bookmark}
                      alt="bookmark"
                      style={{
                        // visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                        cursor: 'pointer',
                        height: '25px',
                      }}
                      onClick={() => {
                        onSaveCard(
                          propertyItem?._id,
                          propertyItem?.buildingName,
                          'property',
                          ['representativeInfo'],
                          'commercial',
                        );
                      }}
                    />
                  )}
                  {propertyItem?.isSaved && (
                    <img
                      src={bookmark_selected}
                      alt="bookmark_selected"
                      style={{
                        // visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                        cursor: 'pointer',
                        height: '25px',
                      }}
                      onClick={() => {
                        onDeleteCard(
                          propertyItem?._id,
                          propertyItem?.buildingName,
                          'property',
                          ['representativeInfo'],
                          'commercial',
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <Rate
                allowHalf
                disabled
                defaultValue={!isNaN(Number(propertyItem?.averageRating)) ? Number(propertyItem?.averageRating) : 0}
              />
              <div className="d-flex  d-column g-5 mt-10">
                <div className="d-flex g-5">
                  <img
                    src={location}
                    style={{ marginTop: 3 }}
                    alt="location"
                    className="propertyListContainer__div__card__row1__col1__row__col__img"
                  />
                  <span className="propertyListContainer__div__card__row1__col1__row__col1__para">
                    <Tooltip title={populateAddressInfo(propertyItem?.addressInfo)}>
                      {truncate(populateAddressInfo(propertyItem?.addressInfo), { length: 70 })}
                    </Tooltip>
                  </span>
                </div>
                <div className="d-flex g-5 a-center">
                  <img
                    src={aminities}
                    alt="aminities"
                    className="propertyListContainer__div__card__row1__col1__row__col__img"
                  />
                  <p className="propertyListContainer__div__card__row1__col1__row__col1__para">
                    <span className="bold">Amenities: </span> Grade of building: A
                  </p>
                </div>
                <div style={{ display: 'inline-flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  {propertyItem?.amenities && (
                    <div
                      className="propertyListContainer__div__card__row1__col1__div"
                      style={{
                        maxHeight: '200px',
                        display: 'flex',
                        width: propertyItem?.amenities && propertyItem?.amenities?.length < 4 ? '100%' : '255px',
                      }}
                    >
                      {propertyItem?.amenities &&
                        propertyItem?.amenities?.length > 0 &&
                        propertyItem?.amenities?.slice(0, 4)?.map((item, index) => {
                          return (
                            <Col
                              span={propertyItem?.amenities && propertyItem?.amenities?.length < 4 ? 6 : 4}
                              className="propertyListContainer__div__card__row1__col1__div__col"
                              style={{ flex: index === 0 ? 'initial' : 'auto' }}
                            >
                              {isEmpty(AmenitiesIconEnum?.[item.toLowerCase()]) && (
                                <img
                                  title={'DEFAULT'}
                                  src={AmenitiesIconEnum?.['DEFAULT']}
                                  className="propertyListContainer__div__card__row1__col1__div__col__img"
                                />
                              )}
                              {!isEmpty(AmenitiesIconEnum?.[item.toLowerCase()]) && (
                                <img
                                  title={item}
                                  src={AmenitiesIconEnum?.[item.toLowerCase()]}
                                  className="propertyListContainer__div__card__row1__col1__div__col__img"
                                />
                              )}
                              {/* <p
                                className="propertyListContainer__div__card__row1__col1__div__col__para"
                                title={item}
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '300px', // Adjust the width as needed
                                }}
                              >
                                {item}
                              </p> */}
                            </Col>
                          );
                        })}
                    </div>
                  )}
                  {propertyItem?.amenities?.length > 4 ? (
                    <p
                      className="propertyListContainer__div__card__row1__col1__div__col__para"
                      style={{ verticalAlign: 'top', marginTop: '5px' }}
                    >
                      {`${propertyItem?.amenities?.length - 4} more...`}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="bottomRow d-flex jc-center">
              <span
                onClick={navigateToPropertyDetail}
                className="propertyListContainer__div__card__row1__col1__row__col1__para"
                style={{ color: '#0080FC' }}
              >
                View All Transaction
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SelectedPropertyDetail;
