import { RightOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Avatar, Rate, Divider, List } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import SimilarCompaniesSkeleton from './skeletons/SimilarCompaniesSkeleton';
import { isEmpty } from 'lodash';
import { getOrganizationFromProperty } from '../../../../features/searchSlice';
import './companyDetails.scss';

const SimilarCompanies = ({ companyData = [], loader }) => {
  const { hasMoreSimilarCompaniesData } = useSelector((store) => store.search);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const { propertyId } = useParams();

  const loadMoreData = () => {
    dispatch(getOrganizationFromProperty(propertyId));
  };
  return (
    <div className="similarPropertyContainer d-flex  g-10 d-column">
      <div>Other Companies in Selected Property</div>
      <div className="similarCompanyScrollbar jc-center pr-10" style={{ maxHeight: 500, overflowY: 'scroll' }}>
        {loader ? (
          [...new Array(5)]?.map(() => <SimilarCompaniesSkeleton key={nanoid()} />)
        ) : isEmpty(companyData) ? (
          'No Similar Company Found'
        ) : (
          <InfiniteScroll
            dataLength={companyData?.length}
            next={() => {
              loadMoreData();
            }}
            hasMore={hasMoreSimilarCompaniesData}
            endMessage={<Divider plain>It is all, nothing more </Divider>}
            scrollableTarget="columnOneDiv"
          >
            <List
              dataSource={companyData}
              renderItem={(ele) => (
                <List.Item
                  key={nanoid()}
                  onClick={() => {
                    navigateTo(`/leadgen/organizationDetails/${ele?._id}`);
                  }}
                  style={{ borderBlockEnd: 'none', display: 'block', width: '100%', padding: '0px' }}
                >
                  <div key={nanoid()} className="d-flex a-center jc-between mt-15 p-10 similarPropertiesHover">
                    <span className="d-flex g-10 jc-between">
                      <Avatar className="companyAvatar" src={ele?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage} />
                      <span className="d-flex d-column jc-between">
                        <span className="font12 fontExtraLight">{ele?.companyName}</span>
                        <Rate
                          allowHalf
                          disabled
                          defaultValue={!isNaN(Number(ele?.averageRating)) ? Number(ele?.averageRating) : 0}
                        />
                      </span>
                    </span>
                    <RightOutlined className="fontLight-2" />
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default SimilarCompanies;
