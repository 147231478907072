import { UserOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider } from 'antd';
import { formatDistanceToNow } from 'date-fns';
import React, { useEffect, useState } from 'react';
import InputEmoji from 'react-input-emoji';
import { useDispatch, useSelector } from 'react-redux';
import {
  getComments,
  socialAppFollowUser,
  socialAppUnFollowUser,
  updateActivity,
  updateComment,
  deleteComment,
} from '../../../../features/socialAppSlice';
import GenericPost from '../allPosts/genericPost/GenericPost';
import PostHeader from '../postHeader/PostHeader';
import './CommentComponent.scss';
import PostFooter from './PostFooter';
import ReportCardPost from '../allPosts/reportCardPost/ReportCardPost';

const CommentComponent = ({ isUserPost, setAllPosts, setPostData, userId, postId, postDetails }) => {
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.socialAppSlice.socialAppComments);
  const loading = useSelector((state) => state.socialApp?.socialApploader);
  const error = useSelector((state) => state.socialApp?.error);

  const [newComment, setNewComment] = useState('');
  const [editCommentText, setEditCommentText] = useState('');
  const [editingComment, setEditingComment] = useState(null);

  useEffect(() => {
    dispatch(getComments({ userId, postId }));
  }, [dispatch, userId, postId]);

  const handleAddComment = async () => {
    if (newComment.trim() !== '') {
      dispatch(updateActivity({ userId, postId, action: 'comments', text: newComment }))?.then((res) => {
        if (res?.payload) {
          dispatch(getComments({ userId, postId }));
          if (isUserPost) {
            setPostData((prev) => ({
              ...prev,
              comments: prev?.comments || 0 + 1,
            }));
          }
          setAllPosts((prev) => ({
            ...prev,
            posts: prev?.posts?.map((elem) => {
              if (elem?._id === postDetails?._id) {
                elem = { ...elem, comments: (elem?.comments || 0) + 1 };
              }
              return elem;
            }),
          }));
        }
      });
      setNewComment('');
    }
  };

  const onAvatarClick = () => {
    // navigateTo('123');
  };

  const handleFollow = (userId) => {
    return dispatch(
      socialAppFollowUser({
        userToFollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isFollowed: true,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isFollowed: true };
            }
            return elem;
          }),
        }));
      }
    });
  };
  const handleUnFollow = (userId) => {
    return dispatch(
      socialAppUnFollowUser({
        userToUnfollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isFollowed: false,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isFollowed: false };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleEditComment = (comment) => {
    setEditingComment(comment);
    setEditCommentText(comment.text);
  };

  const handleUpdateComment = async () => {
    if (editCommentText.trim() !== '') {
      dispatch(updateComment({ userId, commentId: editingComment._id, text: editCommentText })).then((res) => {
        if (res?.payload) {
          dispatch(getComments({ userId, postId }));
          setEditingComment(null);
          setEditCommentText('');
        }
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingComment(null);
    setEditCommentText('');
  };

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment({ userId, commentId })).then((res) => {
      if (res?.payload) {
        {
          dispatch(getComments({ userId, postId }));
          if (isUserPost) {
            setPostData((prev) => ({
              ...prev,
              comments: prev?.comments || 0 - 1,
            }));
          }
          setAllPosts((prev) => ({
            ...prev,
            posts: prev?.posts?.map((elem) => {
              if (elem?._id === postDetails?._id) {
                elem = { ...elem, comments: (elem?.comments || 0) - 1 };
              }
              return elem;
            }),
          }));
        }
      }
    });
  };

  const sortedComments = comments.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const postTypes = {
    generic_card: GenericPost,
    report_card: ReportCardPost,
    news_card: GenericPost,
  };

  const PostComponent = postTypes?.[postDetails?.type];
  return (
    <div className="comment-modal-content">
      <div className="modal-post">
        <div className="post-left">
          <PostHeader
            isUserPost={isUserPost}
            handleFollow={handleFollow}
            handleUnFollow={handleUnFollow}
            postDetails={postDetails}
          />
          <div>
            <PostComponent postDetails={postDetails} />
          </div>
          <div style={{ marginTop: '-30px' }}>
            <PostFooter
              setPostData={setPostData}
              isUserPost={isUserPost}
              setAllPosts={setAllPosts}
              postDetails={postDetails}
            />
            <div style={{ width: '440px', marginLeft: '394px', marginTop: '-10px' }}>
              <Divider style={{ borderColor: '#888' }} className="comment-divider" />
            </div>
          </div>
        </div>
        <div
          style={{ display: 'flex', alignItems: 'end', marginTop: '-25px', marginLeft: '20px' }}
          className="post-right"
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
              width: '54%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Avatar
              className="avatar"
              onClick={onAvatarClick}
              src={''}
              icon={<UserOutlined />}
              style={{ alignContent: 'center', width: '40px', height: '40px' }}
            />
            <div className="comment-input-container">
              <InputEmoji
                maxLength={100}
                keepOpened
                theme="auto"
                value={newComment}
                onChange={setNewComment}
                cleanOnEnter
                inputClass="emojiOverlay"
                placeholder="Type a message"
              />

              <Button
                style={{ borderRadius: '8px', color: '#4096ff', borderColor: '#4096ff', background: 'transparent' }}
                onClick={handleAddComment}
                ghost
              >
                Post
              </Button>
            </div>
          </div>
          <div className="comments-list">
            {loading && <p>Loading comments...</p>}
            {error && <p>{error}</p>}
            {sortedComments.length > 0 ? (
              sortedComments.map((comment, index) => (
                <div key={index} className="d-flex g-10">
                  <Avatar
                    className="avatar"
                    onClick={onAvatarClick}
                    src={comment?.userDetails?.personalDetails?.imageUrl}
                    icon={<UserOutlined />}
                    style={{ alignContent: 'center', width: '40px', height: '40px' }}
                  />
                  <div
                    style={{
                      backgroundColor: '#f2f2f2',
                      padding: '10px',
                      borderRadius: '10px',
                      flex: 2,
                    }}
                    key={comment._id}
                    className="comment-item"
                  >
                    <div className="comment-header">
                      <span className="font14 font700">{comment.userDetails.name}</span>
                      <span className="font12" style={{ color: '#888' }}>
                        {formatDistanceToNow(new Date(comment.createdAt), { addSuffix: true })}
                      </span>
                    </div>
                    <div className="font12" style={{ color: '#888' }}>
                      {comment.userDetails.professionalDetails.companyName}
                    </div>
                    {editingComment && editingComment._id === comment._id ? (
                      <div className="comment-edit-container">
                        <InputEmoji
                          maxLength={100}
                          keepOpened
                          theme="auto"
                          value={editCommentText}
                          onChange={setEditCommentText}
                          cleanOnEnter
                          inputClass="emojiOverlay"
                          placeholder="Edit your comment"
                        />
                        <div className="comment-edit-buttons-container">
                          <Button
                            style={{
                              borderRadius: '8px',
                              color: '#4096ff',
                              borderColor: '#4096ff',
                              background: 'transparent',
                            }}
                            onClick={handleUpdateComment}
                            ghost
                          >
                            Save
                          </Button>
                          <Button
                            style={{
                              borderRadius: '8px',
                              color: '#ff4d4f',
                              borderColor: '#ff4d4f',
                              background: 'transparent',
                            }}
                            onClick={handleCancelEdit}
                            ghost
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="comment-text font14">
                        <span>{comment.text}</span>
                        {comment.userId === userId && (
                          <div id="comment-actions" className="comment-actions">
                            <EditOutlined
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleEditComment(comment)}
                              className="edit-button"
                            />
                            <DeleteOutlined onClick={() => handleDeleteComment(comment._id)} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p style={{ marginLeft: '48px' }}>No comments yet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommentComponent;
