import { CaretRightFilled, CaretRightOutlined, RightOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Avatar, Rate } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import SimilarCompaniesSkeleton from '../CompanyDetails/skeletons/SimilarCompaniesSkeleton';
import { useDispatch } from 'react-redux';
import { viewMoreProperty } from '../../../../features/searchSlice';
import './propertyDetails.scss';

const SimilarProperties = ({ propertyData = [], searchQuery, loader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onPropertyClick = (id) => {
    dispatch(viewMoreProperty(id));
  };
  return (
    <div style={{ overflow: 'scroll' }} className="similarPropertyContainer d-flex  g-10 d-column">
      {searchQuery?.body?.tag === 'commercialProperty' || searchQuery?.body?.tag === 'residentialProperty' ? (
        <div>Similar Properties</div>
      ) : (
        <div>Other Properties of {searchQuery?.body?.query}</div>
      )}
      <div className="similarPropertyScrollbar jc-center pr-10" style={{ maxHeight: '500px', overflow: 'scroll' }}>
        {propertyData?.map((ele) =>
          loader ? (
            <SimilarCompaniesSkeleton />
          ) : (
            <div
              onClick={() => onPropertyClick(ele?._id)}
              key={nanoid()}
              className="d-flex a-center jc-between mt-15 p-10 similarPropertiesHover"
            >
              <span className="d-flex g-10 jc-between">
                <Avatar src={ele?.assetsInfo?.[0]?.featuredImage} />
                <span className="d-flex d-column jc-between">
                  <span className="font12 fontExtraLight cursor-pointer">{ele?.buildingName}</span>
                  <Rate
                    allowHalf
                    disabled
                    defaultValue={!isNaN(Number(ele?.averageRating)) ? Number(ele?.averageRating) : 0}
                  />
                </span>
              </span>
              <RightOutlined className="fontLight-2" />
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default SimilarProperties;
