// Css Imports
import './socialApp.scss';
import { Card, Divider, Empty, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';
import genIcon from '../../../assets/LeadGen.png';
import dayjs from 'dayjs';
// React,React-Redux,Router Imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddPost from '../../components/socialApp/posts/addPost/AddPost';
import AddPostPopup from '../../components/socialApp/posts/addPost/AddPostPopup';
import AllPosts from '../../components/socialApp/posts/allPosts/AllPosts';
import { getSocialDashbordPosts, setSearchVisibleSocialApp } from '../../features/socialAppSlice';
import AnnouncementsCardDashboard from '../../../leadgen/components/announcementsCardDashboard/AnnouncementsCardDashboard';
import { getRecentActivitiesData } from '../../../utils/referenceData/userDashboard';
import { fetchAccouncementsData, fetchRecentActivities } from '../../features/userDashboardSlice';
import { isEmpty, truncate } from 'lodash';

// Slices and reducer imports

// Component Imports

const SocialApp = () => {
  const { recentActivities } = useSelector((store) => store.userDashboard);
  const { announcements } = useSelector((store) => store.userDashboard);

  // Hooks
  const dispatch = useDispatch();

  // // States
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [allPosts, setAllposts] = useState([]);
  const [showCreatePostPopup, setShowCreatePopup] = useState(false);
  const { user } = useSelector((store) => store.user);

  // fetching on mounting
  useEffect(() => {
    setOffset(0);
    dispatch(setSearchVisibleSocialApp(true));
    dispatch(fetchRecentActivities());
    dispatch(fetchAccouncementsData());
    dispatch(
      getSocialDashbordPosts({
        offset: offset,
        limit: limit,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllposts(res?.payload?.data?.response?.data);
      }
    });
    return () => dispatch(setSearchVisibleSocialApp(false));
  }, [dispatch, offset]);

  // handleing infinite scroll
  const handleScroll = () => {
    setOffset((prev) => prev + limit);
    dispatch(
      getSocialDashbordPosts({
        offset: offset + limit,
        limit: limit,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllposts((prev) => {
          return {
            ...prev,
            posts: [...(prev?.posts || []), ...(res?.payload?.data?.response?.data?.posts || [])],
            totalRecords: res?.payload?.data?.response?.data?.totalRecords,
          };
        });
      }
    });
  };

  // on close popup
  const onCloseCreatePopup = () => {
    setShowCreatePopup(false);
  };
  return (
    <div className="socialAppContainer">
      <div className="socialAppContainer__postContainer">
        {/* -----Add post------ */}

        <AddPost imageUrl={user?.personalDetails?.imageUrl} setShowCreatePopup={setShowCreatePopup} />

        {/* -----Add post------ */}

        {/* -----ALl post------ */}
        <AllPosts setAllposts={setAllposts} handleScroll={handleScroll} allPosts={allPosts} />
        {/* -----ALl post------ */}

        <div className="socialAppContainer__postContainer--post"></div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div className="socialAppContainer__rightContainer">
          <span style={{ fontSize: '20px', fontWeight: '600' }}>Announcements</span>
          <div
            style={{ overflow: 'scroll', height: '100%' }}
            className="socialAppContainer__rightContainer--insideContainer"
          >
            {isEmpty(announcements) ? (
              <Empty className="announcement-desc" description="No Announcements" />
            ) : (
              announcements?.map((el, i) => {
                return <AnnouncementsCardDashboard data={el} key={i} />;
              })
            )}
          </div>
        </div>
        <Card style={{ flex: 1.2 }}>
          <Title style={{ fontSize: '20px' }} level={4}>
            Recent Activity
          </Title>
          <div className="userdashboard-activity">
            {recentActivities?.length > 0 ? (
              recentActivities?.map((el, index) => {
                return (
                  <div key={index}>
                    <Divider />
                    <div className="userDashboard__activity-item">
                      <div className="activity-item__action">
                        <div style={{ marginRight: '10px' }} className="userDashboard__activity-icon">
                          <img src={genIcon} alt="" width={30} />
                        </div>
                        <span className="userDashboard__activity-message">
                          <Tooltip title={el.message}>{truncate(el.message, { length: 40 })}</Tooltip>
                        </span>
                      </div>

                      <span className="userDashboard__activity-time">
                        {dayjs(el?.createdAt).format('DD-MM-YYYY hh:mm')}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <span>No Recent Activity</span>
            )}
          </div>
        </Card>
      </div>
      {/* -----------Add post pop over----------- */}
      <AddPostPopup setAllposts={setAllposts} open={showCreatePostPopup} closePopup={onCloseCreatePopup} />
      {/* -----------Add post pop over----------- */}
    </div>
  );
};

export default SocialApp;
