import { nanoid } from '@reduxjs/toolkit';
import { Card, Col, Divider, List, Rate, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import aminities from '../../../../../assets/images/Icon feather-home.svg';
import location from '../../../../../assets/images/Icon metro-location.svg';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import AmenitiesIconEnum from '../../../../../utils/referenceData/search/amenitiesIconEnum';
import { getSearchResults } from '../../../../features/searchSlice';
import '../../../searchPage/searchPage.scss';

const ResidentialProperty = ({
  propertyData,
  handleExpandPropertyCard,
  onSaveCard,
  onDeleteCard,
  onSaveAllCard,
  onDeleteAllCard,
}) => {
  const [openOverflowMenu, setOpenOverflowMenu] = useState(false);
  const { searchQuery, pagination, hasMoreData, isSavedAllResidentialProperty } = useSelector((store) => store.search);
  const navigateTo = useNavigate();

  const dispatch = useDispatch();

  const loadMoreData = () => {
    dispatch(
      getSearchResults({
        body: {
          query: searchQuery?.body?.query,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    );
  };

  const handleSelectAllProperties = () => {
    const filteredProperties = propertyData
      ?.filter((el) => !el?.isSaved && el?.isResourceLocked)
      ?.map((data) => {
        return {
          resourceId: data?._id,
          name: data?.buildingName,
          resourceSubType: 'property',
          unlockedFields: ['representativeInfo'],
          resourceType: 'residential',
        };
      });
    return filteredProperties;
  };

  const handleUnSelectAllProperties = () => {
    const filteredProperties = propertyData
      ?.filter((el) => el?.isSaved && el?.isResourceLocked)
      ?.map((data) => {
        return {
          resourceId: data?._id,
          name: data?.buildingName,
          resourceSubType: 'property',
          unlockedFields: ['representativeInfo'],
          resourceType: 'residential',
        };
      });
    return filteredProperties;
  };

  return isEmpty(propertyData) ? (
    <></>
  ) : (
    <div style={{ paddingBottom: '200px' }}>
      {!isEmpty(propertyData) && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', height: '30px', gap: '30px' }}>
          {/* <div style={{ fontWeight: '600', color: '#707070' }}>Search Results</div> */}
          <div style={{ color: '#9f9f9f', marginLeft: '126px' }}>
            Your search result for Residential property as{' '}
            <i style={{ color: '#48a4fc', fontWeight: '600' }}>{searchQuery?.body?.query}</i> and similar properties
          </div>
        </div>
      )}
      <div id="columnOneDiv" style={{ height: '100vh', overflow: 'auto', paddingBottom: '100px' }}>
        <InfiniteScroll
          dataLength={propertyData?.length}
          next={() => {
            loadMoreData();
          }}
          hasMore={hasMoreData}
          endMessage={<Divider plain>It is all, nothing more </Divider>}
          scrollableTarget="columnOneDiv"
        >
          <List
            dataSource={propertyData}
            renderItem={(propertyItem) => (
              <List.Item key={nanoid()} style={{ borderBlockEnd: 'none' }}>
                <div key={propertyItem?._id} className="propertyListContainer__div">
                  <Card className="propertyListContainer__div__card">
                    <Row className="inside_row">
                      <Col
                        span={7}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        className="propertyListContainer__div__card__row__col__row__col"
                      >
                        {propertyItem?.assetsInfo?.[0]?.featuredImage ? (
                          <img
                            src={propertyItem?.assetsInfo?.[0]?.featuredImage}
                            alt="Property Img"
                            className="propertyListContainer__div__card__row__col__row__col__img"
                          />
                        ) : (
                          <LottieAnimation height={70} width={100} animationData={animationData} loop={true} />
                        )}
                      </Col>
                      <Col span={16} className="d-flex d-column jc-between">
                        <div>
                          <div className="d-flex jc-between a-center" style={{ marginTop: '8px' }}>
                            <h5
                              style={{ color: '#0081FC' }}
                              className="text-underline propertyListContainer__div__card__row__col__row__col1__h5"
                              onClick={() => {
                                navigateTo(`/leadgen/propertyDetails/${propertyItem?._id}`);
                              }}
                            >
                              {propertyItem?.buildingName}
                            </h5>
                            <div>
                              {!propertyItem?.isSaved && (
                                <img
                                  src={bookmark}
                                  alt="bookmark"
                                  style={{
                                    visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                    cursor: 'pointer',
                                    height: '25px',
                                  }}
                                  onClick={() => {
                                    onSaveCard(
                                      propertyItem?._id,
                                      propertyItem?.buildingName,
                                      'property',
                                      ['representativeInfo'],
                                      'commercial',
                                    );
                                  }}
                                />
                              )}
                              {propertyItem?.isSaved && (
                                <img
                                  src={bookmark_selected}
                                  alt="bookmark_selected"
                                  style={{
                                    visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                    cursor: 'pointer',
                                    height: '25px',
                                  }}
                                  onClick={() => {
                                    onDeleteCard(
                                      propertyItem?._id,
                                      propertyItem?.buildingName,
                                      'property',
                                      ['representativeInfo'],
                                      'commercial',
                                    );
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className="propertyListContainer__div__card__row__col__row__col1__para"
                            style={{ color: '#313131' }}
                          >
                            {`${propertyItem?.addressInfo?.[0]?.locality}, ${propertyItem?.addressInfo?.[0]?.city}`}
                          </div>
                          <Rate
                            allowHalf
                            disabled
                            defaultValue={
                              !isNaN(Number(propertyItem?.averageRating)) ? Number(propertyItem?.averageRating) : 0
                            }
                          />
                          <div className="d-flex  d-column g-5 mt-10">
                            <div className="d-flex g-5">
                              <img
                                src={location}
                                style={{ marginTop: 3 }}
                                alt="location"
                                className="propertyListContainer__div__card__row1__col1__row__col__img"
                              />
                              <span className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                {populateAddressInfo(propertyItem?.addressInfo?.[0])}
                              </span>
                            </div>
                            <div className="d-flex g-5 a-center">
                              <img
                                src={aminities}
                                alt="aminities"
                                className="propertyListContainer__div__card__row1__col1__row__col__img"
                              />
                              <p className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                Amenities: Grade of building: A
                              </p>
                            </div>
                            <div style={{ display: 'inline-flex', justifyContent: 'space-around' }}>
                              {propertyItem?.amenities && (
                                <div
                                  className="propertyListContainer__div__card__row1__col1__div"
                                  style={{ maxHeight: '200px', display: 'flex' }}
                                >
                                  {propertyItem?.amenities &&
                                    propertyItem?.amenities?.length > 0 &&
                                    propertyItem?.amenities?.slice(0, 3)?.map((item) => {
                                      return (
                                        <Col
                                          span={6}
                                          className="propertyListContainer__div__card__row1__col1__div__col"
                                        >
                                          {isEmpty(AmenitiesIconEnum[item]) && (
                                            <img
                                              title={'DEFAULT'}
                                              src={AmenitiesIconEnum['DEFAULT']}
                                              className="propertyListContainer__div__card__row1__col1__div__col__img"
                                            />
                                          )}
                                          {!isEmpty(AmenitiesIconEnum[item]) && (
                                            <img
                                              title={item}
                                              src={AmenitiesIconEnum[item]}
                                              className="propertyListContainer__div__card__row1__col1__div__col__img"
                                            />
                                          )}
                                          {/* <p
                                            className="propertyListContainer__div__card__row1__col1__div__col__para"
                                            title={item}
                                            style={{
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              maxWidth: '300px', // Adjust the width as needed
                                            }}
                                          >
                                            {item}
                                          </p> */}
                                        </Col>
                                      );
                                    })}
                                </div>
                              )}
                              {propertyItem?.amenities?.length > 3 ? (
                                <p
                                  className="propertyListContainer__div__card__row1__col1__div__col__para"
                                  style={{ verticalAlign: 'top', marginTop: '5px' }}
                                >
                                  {`${propertyItem?.amenities?.length - 3} more...`}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="bottomRow d-flex jc-between">
                          <span
                            className="propertyListContainer__div__card__row1__col1__row__col1__para"
                            style={{ color: '#0080FC', cursor: 'pointer', margin: 'auto' }}
                          >
                            View All Transaction
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ResidentialProperty;
