import { Avatar, Rate } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import './companyDetails.scss';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';

const SelectedCompanyDetails = ({ companyData, onDeleteCard, onSaveCard }) => {
  // hooks
  const navigateTo = useNavigate();
  return (
    <div key={companyData?._id} className="position-relative">
      <div className="companyCard">
        <div className=" g-5 mt-10 flex-1">
          <div className="d-flex jc-between a-center mt-5 ">
            <div className="d-flex a-center g-5">
              <Avatar
                className="companyAvatar"
                src={companyData?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage || 'https://place-hold.it/700'}
              />
              <div>
                <h5
                  style={{ color: '#0081FC' }}
                  className="text-underline companyContainer__div__card__row__col__row__col1__h5 cursor-pointer"
                  onClick={() => navigateTo(`/leadgen/organizationDetails/${companyData?._id}`)}
                >
                  {companyData?.companyName}
                </h5>
                <Rate
                  allowHalf
                  disabled
                  defaultValue={!isNaN(Number(companyData?.averageRating)) ? Number(companyData?.averageRating) : 0}
                />
              </div>
            </div>
            <div>
              {!companyData?.isSaved && (
                <img
                  src={bookmark}
                  alt="bookmark"
                  style={{
                    visibility: !companyData?.isResourceLocked ? 'hidden' : 'visible',
                    cursor: 'pointer',
                    height: '25px',
                  }}
                  onClick={() => {
                    onSaveCard(
                      companyData?._id,
                      companyData?.companyName,
                      'organization',
                      ['directorInfo', 'propertyEngagement'],
                      'commercial',
                    );
                  }}
                />
              )}
              {companyData?.isSaved && (
                <img
                  src={bookmark_selected}
                  alt="bookmark_selected"
                  style={{
                    visibility: !companyData?.isResourceLocked ? 'hidden' : 'visible',
                    cursor: 'pointer',
                    height: '25px',
                  }}
                  onClick={() => {
                    onDeleteCard(
                      companyData?._id,
                      companyData?.companyName,
                      'organization',
                      ['directorInfo', 'propertyEngagement'],
                      'commercial',
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex w-50 d-column font10 fontLight-2" style={{ marginLeft: '5px' }}>
              <div className="fontLight-2 mt-15">Registered Address :</div>
              <div className="fontLight-2 mt-15">{populateAddressInfo(companyData?.companyAddresses[0])}</div>
            </div>
            <div
              className="mt-20"
              style={{
                backgroundColor: '#707070',
                width: '1px' /* Adjust the width as needed */,
                height: '80px',
                marginRight: '15px',
              }}
            ></div>
            <div className="d-flex w-50 d-column font10 fontLight-2 ">
              <div className="fontLight-2 mt-10 p-5">
                Floor No :{companyData?.propertyEngagement?.floorNo || ' Awaited'}
              </div>
              <div className="fontLight-2 mt-10 p-5">
                Unit No :{companyData?.propertyEngagement?.unitNo || ' Awaited'}
              </div>
              <div className="fontLight-2 mt-10 p-5">Industry :{companyData?.industryType || ' Awaited'}</div>
            </div>
          </div>
        </div>
        <div className="bottomRow  d-flex jc-center">
          <span
            className="font10 cursor-pointer"
            style={{ color: '#0080FC' }}
            onClick={() => navigateTo(`/leadgen/organizationDetails/${companyData?._id}`)}
          >
            View More Details
          </span>
        </div>
      </div>
      <span className="connectorLine">
        {[...new Array(200)]?.map(() => (
          <>&#x2022;</>
        ))}
      </span>
    </div>
  );
};

export default SelectedCompanyDetails;
