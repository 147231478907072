import { CameraOutlined, FileOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Flex, Form, Input, Row, Select, Spin, Typography, Upload, message } from 'antd';
import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// import { socialAppCreatePost, uploadPostImage } from '../../../../../../../features/socialAppSlice';
import { socialAppCreatePost, uploadPostImage } from '../../../../../features/socialAppSlice';
import PostTags from '../GenericPostForm/common/PostTags';
import ReactQuillComponent from '../GenericPostForm/common/ReactQuillComponent';
import './reportPostForm.scss';

const NewsPostForm = ({ setShowForm, setAllposts, isCompany, postRel = '' }) => {
  // hooks
  const dispatch = useDispatch();
  const { Text } = Typography;

  // states
  const [selectedUploadType, setSelectedUploadType] = useState('IMAGE');
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);
  const userv2 = useSelector((state) => state.user?.userV2);
  const userId = useSelector((state) => state.user?.user?.id);

  // form derails
  const [formDetails] = Form.useForm();

  // states
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImageloader] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [imgNames, setImgNames] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoNames, setVideoNames] = useState([]);
  const [audioUrls, setAudioUrls] = useState([]);
  const [audioNames, setAudioNames] = useState([]);
  const [audioCoverUrls, setAudioCoverUrls] = useState([]);
  const [audioCoverNames, setAudioCoverNames] = useState([]);

  const typeMap = {
    image: {
      names: setImgNames,
      urls: setImgUrls,
      nameState: imgNames,
      urlState: imgUrls,
    },
    video: {
      names: setVideoNames,
      urls: setVideoUrls,
      nameState: videoNames,
      urlState: videoUrls,
    },
    audio: {
      names: setAudioNames,
      urls: setAudioUrls,
      nameState: audioNames,
      urlState: audioUrls,
    },
    audiocover: {
      names: setAudioCoverNames,
      urls: setAudioCoverUrls,
      nameState: audioCoverNames,
      urlState: audioCoverUrls,
    },
  };
  // handle image upload
  const handleUpload = async (file, type = 'image') => {
    const formData = new FormData();
    formData.append('files', file);
    setImageloader(type);
    dispatch(uploadPostImage(formData))?.then((res) => {
      setImageloader(false);
      message.success(`${file?.name} uploaded successfuly...`);
      if (res?.payload?.data?.response?.data) {
        const setterFuncNames = typeMap?.[type?.toLowerCase()]?.names;
        const setterFuncUrls = typeMap?.[type?.toLowerCase()]?.urls;
        setterFuncNames((prev) => [...(prev || []), file?.name]);
        setterFuncUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
      }
    });
  };

  // handle submit
  const handleFormSubmit = (e) => {
    const createPostPayload = {
      title: e?.post_title || '',
      type: 'report_card',
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
      imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
      postDetails: {
        body: e?.post_caption,
        synopsys: e?.post_synopsys,
        tags: allTags?.length > 0 ? allTags : undefined,
        link: e?.link,
        // pdfUrl: filesUrls?.[0],
        pdfUrl: audioUrls?.[0],
      },
    };

    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        const addedPost = {
          _id: res?.payload?.data?.response?.data?._id,
          type: 'report_card',
          title: e?.post_title || '',
          body: e?.post_caption || '',
          ownerId: userId,
          ownerType: 'user_post',
          postDetails: {
            body: e?.post_caption || '',
            synopsys: e?.post_synopsys || '',
            tags: e?.post_synopsys || [],
            link: e?.post_synopsys || '',
            pdfUrl: e?.filesUrls?.[0],
          },
          likes: 0,
          comments: 0,
          imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
          isOwner: true,
          ownerDetails: {
            _id: userId,
            name: userv2?.name,
            email: userv2?.email,
            professionalDetails: userv2?.personalDetails,
          },
        };
        setAllposts((prev) => ({
          ...prev,
          posts: [addedPost, ...prev?.posts],
          totalRecords: prev?.totalRecords + 1,
        }));
        message.success('Post created successfuly...');
        setShowForm(false);
      } else {
        message.error('Some error occured...');
      }
    });
  };
  const uploadTypes = [
    {
      key: 'IMAGE',
      icon: CameraOutlined,
    },
    {
      key: 'VIDEO',
      icon: CameraOutlined,
    },
    {
      key: 'AUDIO',
      icon: CameraOutlined,
    },
  ];
  const uploadTypesKeyMap = {
    IMAGE: {
      formats: '.png,.jpeg,.jpg',
      type: 'image',
      label: 'Upload Image',
    },
    VIDEO: {
      formats: '.mp3,.mp4,.3gp',
      type: 'video',
      label: 'Upload Video',
    },
    AUDIO: {
      label: 'Upload Audio',
      formats: '.mp3,.mp4,.3gp',
      type: 'audio',
    },
  };

  return (
    <Form
      style={{ paddingTop: 20 }}
      // className="d-flex"
      layout="vertical"
      form={formDetails}
      onFinish={handleFormSubmit}
    >
      <div>
        <div className="d-flex g-5 mb-20 pl-20">
          {uploadTypes?.map((elem) => {
            const Icon = elem?.icon;
            return (
              <span
                onClick={() => setSelectedUploadType(elem?.key)}
                className={`uploadIcon ${selectedUploadType == elem?.key && 'uploadIconSelected'}`}
              >
                <Icon />
              </span>
            );
          })}
        </div>
        <div className="d-flex g-5 pl-20">
          <Form.Item name={selectedUploadType} className="dropdown-form-item">
            <div className="d-flex d-column g-20">
              <Upload
                listType="picture-card"
                className="uploadImage"
                multiple
                accept={uploadTypesKeyMap?.[selectedUploadType]?.formats}
                customRequest={({ file }) => handleUpload(file, uploadTypesKeyMap?.[selectedUploadType]?.type)}
                showUploadList={false}
              >
                {imgLoader == uploadTypesKeyMap?.[selectedUploadType]?.type ? (
                  <Spin indicator={<LoadingOutlined />} />
                ) : (
                  <>
                    {' '}
                    <UploadOutlined style={{ fontSize: 28, paddingBottom: '1rem' }} />{' '}
                    <Text>{uploadTypesKeyMap?.[selectedUploadType]?.label}</Text>
                  </>
                )}
              </Upload>
              {typeMap?.[selectedUploadType?.toLowerCase]?.names?.length > 0 && (
                <div className="d-flex d-column g-5" style={{ maxHeight: '200px', overflow: 'scroll' }}>
                  {typeMap?.[selectedUploadType?.toLowerCase]?.names?.map((elem, index) => (
                    <span className="fontBlue" key={index}>
                      <FileOutlined /> {elem}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </Form.Item>
          {selectedUploadType == 'AUDIO' && (
            <Form.Item name={'audioCover'} className="dropdown-form-item">
              <div className="d-flex d-column g-20">
                <Upload
                  listType="picture-card"
                  className="uploadImage"
                  multiple
                  accept={'.img,.png,.jpg'}
                  customRequest={({ file }) => handleUpload(file, 'audioCover')}
                  showUploadList={false}
                >
                  {imgLoader == 'audioCover' ? (
                    <Spin indicator={<LoadingOutlined />} />
                  ) : (
                    <>
                      {' '}
                      <UploadOutlined style={{ fontSize: 28, paddingBottom: '1rem' }} /> <Text>Upload Cover Image</Text>
                    </>
                  )}
                </Upload>
                {audioCoverNames?.length > 0 && (
                  <div className="d-flex d-column g-5" style={{ maxHeight: '200px', overflow: 'scroll' }}>
                    {audioCoverNames?.map((elem, index) => (
                      <span className="fontBlue" key={index}>
                        <FileOutlined /> {elem}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </Form.Item>
          )}
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <Form.Item required label="Headline" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="post_headline"
              className="buyerrow-form__space--text"
              rules={[
                {
                  required: true,
                  message: 'Add Headline!',
                },
              ]}
            >
              <Input placeholder="Add Headline" style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item required label="Description" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="post_description"
              className="buyerrow-form__space--text"
              rules={[
                {
                  required: true,
                  message: 'Add Description!',
                },
              ]}
            >
              <ReactQuillComponent />
            </Form.Item>
          </Flex>
        </Form.Item>

        <Form.Item label="Link" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="link"
              rules={[
                {
                  message: 'Please enter valid url.',
                  pattern:
                    '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
                },
              ]}
            >
              <Input placeholder="http://" style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Flex>
        </Form.Item>
        <div className="d-flex">
          <Form.Item required label="Places" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="post_places"
                className="buyerrow-form__space--text"
                rules={[
                  {
                    required: true,
                    message: 'Select Location!',
                  },
                ]}
              >
                <Select
                  placeholder="Select Location"
                  options={[
                    { value: 'pune', label: 'Pune' },
                    { value: 'mumbai', label: 'Mumbai' },
                  ]}
                />
              </Form.Item>
            </Flex>
          </Form.Item>
          <Form.Item label="News Category" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item name="post_news_category" className="buyerrow-form__space--text">
                <Select
                  placeholder="Select Category"
                  options={[
                    { value: 'news_one', label: 'News' },
                    { value: 'news_two', label: 'News 2' },
                  ]}
                />
              </Form.Item>
            </Flex>
          </Form.Item>
        </div>
        <div className="d-flex">
          <Form.Item required label="Date" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="post_date"
                className="buyerrow-form__space--text"
                rules={[
                  {
                    required: true,
                    message: 'Select Date!',
                  },
                ]}
              >
                <DatePicker placeholder="DD/MM/YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Flex>
          </Form.Item>
          <Form.Item label="Name of the news publisher" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item name="post_news_publisher" className="buyerrow-form__space--text">
                <Input placeholder="Select Category" style={{ width: '100%' }} />
              </Form.Item>
            </Flex>
          </Form.Item>
        </div>

        <Row>
          <PostTags allTags={allTags} setAllTags={setAllTags} />
        </Row>
        <Row className="d-flex jc-end ">
          <div className="d-flex jc-end g-20 ">
            <Button type="primary" ghost onClick={() => setShowForm(false)}>
              Reset
            </Button>
            <Button htmlType="submit" loader={loader} type="primary">
              Create Post
            </Button>
          </div>
        </Row>
      </div>
    </Form>
  );
};

export default NewsPostForm;
