import { FileOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Row, Spin, Typography, Upload, message } from 'antd';
import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// import { socialAppCreatePost, uploadPostImage } from '../../../../../../../features/socialAppSlice';
import { socialAppCreatePost, uploadPostImage } from '../../../../../features/socialAppSlice';
import PostTags from '../GenericPostForm/common/PostTags';
import ReactQuillComponent from '../GenericPostForm/common/ReactQuillComponent';
import './reportPostForm.scss';

const ReportPostForm = ({ setShowForm, setAllposts, isCompany, postRel = '' }) => {
  // hooks
  const dispatch = useDispatch();
  const { Text } = Typography;

  // states
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);
  const userv2 = useSelector((state) => state.user?.userV2);
  const userId = useSelector((state) => state.user?.user?.id);

  // form derails
  const [formDetails] = Form.useForm();

  // states
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImageloader] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [imgNames, setImgNames] = useState([]);
  const [filesUrls, setFilesUrls] = useState([]);
  const [filesNames, setFilesNames] = useState([]);

  // handle image upload
  const handleUpload = async (file, type = 'image') => {
    const formData = new FormData();
    formData.append('files', file);
    setImageloader(type);
    dispatch(uploadPostImage(formData))?.then((res) => {
      setImageloader(false);
      message.success(`${file?.name} uploaded successfuly...`);
      if (res?.payload?.data?.response?.data) {
        if (type == 'image') {
          setImgNames((prev) => [...(prev || []), file?.name]);
          setImgUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
        } else if ((type = 'file')) {
          setFilesNames((prev) => [...(prev || []), file?.name]);
          setFilesUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
        }
      }
    });
  };

  // handle submit
  const handleFormSubmit = (e) => {
    const createPostPayload = {
      title: e?.post_title || '',
      type: 'report_card',
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
      imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
      postDetails: {
        body: e?.post_caption,
        synopsys: e?.post_synopsys,
        tags: allTags?.length > 0 ? allTags : undefined,
        link: e?.link,
        pdfUrl: filesUrls?.[0],
      },
    };

    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        // const addedPost = {
        //   _id: '66a2a036295dc56e966256db',
        //   type: 'report_card',
        //   title: 'test',
        //   imageUrls: [
        //     {
        //       url: 'https://social-dev-posts-publick.s3.ap-south-1.amazonaws.com/1721933502314-ESZLqLAQ.jpeg',
        //       type: 'image',
        //     },
        //   ],
        //   ownerId: '667ec158f37497a5f37188ca',
        //   ownerType: 'user_post',
        //   postDetails: {
        //     body: '<p>test</p>',
        //     synopsys: 'synopsys',
        //     tags: ['test'],
        //     link: 'http://lorem.ipsum',
        //     pdfUrl:
        //       'https://social-dev-posts-publick.s3.ap-south-1.amazonaws.com/1721918070819-DocumentsRequiredForNameChange.pdf',
        //   },
        //   createdAt: '2024-07-25T18:57:58.560Z',
        //   ownerDetails: {
        //     _id: '667ec158f37497a5f37188ca',
        //     name: 'Manish Jadav',
        //     email: 'manish.jadav@qodequaytech.com',
        //     professionalDetails: {
        //       companyName: 'Realty Builder',
        //       designation: 'asdf',
        //     },
        //   },
        //   isLiked: false,
        //   isSaved: false,
        // };
        const addedPost = {
          _id: res?.payload?.data?.response?.data?._id,
          type: 'report_card',
          title: e?.post_title || '',
          body: e?.post_caption || '',
          ownerId: userId,
          ownerType: 'user_post',
          postDetails: {
            body: e?.post_caption || '',
            synopsys: e?.post_synopsys || '',
            tags: e?.post_synopsys || [],
            link: e?.post_synopsys || '',
            pdfUrl: e?.filesUrls?.[0],
          },
          likes: 0,
          comments: 0,
          imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
          isOwner: true,
          ownerDetails: {
            _id: userId,
            name: userv2?.name,
            email: userv2?.email,
            professionalDetails: userv2?.personalDetails,
          },
        };
        setAllposts((prev) => ({
          ...prev,
          posts: [addedPost, ...prev?.posts],
          totalRecords: prev?.totalRecords + 1,
        }));
        message.success('Post created successfuly...');
        setShowForm(false);
      } else {
        message.error('Some error occured...');
      }
    });
  };

  return (
    <Form
      style={{ paddingTop: 20 }}
      className="d-flex"
      layout="vertical"
      form={formDetails}
      onFinish={handleFormSubmit}
    >
      <div>
        <Form.Item name="imageURL" className="dropdown-form-item">
          <div className="d-flex d-column g-20" style={{ paddingLeft: '20px', marginBottom: '20px' }}>
            <Upload
              listType="picture-card"
              className="uploadImage"
              multiple
              accept=".png,.jpeg,.jpg"
              customRequest={({ file }) => handleUpload(file, 'image')}
              showUploadList={false}
            >
              {imgLoader == 'image' ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                <>
                  {' '}
                  <UploadOutlined style={{ fontSize: 28, paddingBottom: '1rem' }} /> <Text>Upload Image</Text>
                </>
              )}
            </Upload>
          </div>
          <div className="d-flex d-column g-5" style={{ maxHeight: '200px', overflow: 'scroll' }}>
            {imgNames?.map((elem, index) => (
              <span className="fontBlue" key={index}>
                <FileOutlined /> {elem}
              </span>
            ))}
          </div>
        </Form.Item>
        <Form.Item label="Upload PDF" name="pdfURL" className="dropdown-form-item p-0">
          <div className="d-flex d-column g-20 mb-20">
            <Upload
              listType="picture-card"
              className="uploadPDF"
              multiple
              accept=".pdf"
              customRequest={({ file }) => handleUpload(file, 'file')}
              showUploadList={false}
            >
              {imgLoader == 'file' ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                <>
                  {' '}
                  <UploadOutlined style={{ fontSize: 22 }} /> <Text>Select File</Text>
                </>
              )}
            </Upload>
            <div className="d-flex d-column g-5" style={{ maxHeight: '200px', overflow: 'scroll' }}>
              {filesNames?.map((elem, index) => (
                <span className="fontBlue" key={index}>
                  <FileOutlined /> {elem}
                </span>
              ))}
            </div>
          </div>
        </Form.Item>
      </div>
      <div style={{ marginTop: 10 }}>
        <Form.Item required label="Title" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="post_title"
              className="buyerrow-form__space--text"
              rules={[
                {
                  required: true,
                  message: 'Add Post Title!',
                },
              ]}
            >
              <Input placeholder="Add Post Title" style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item required label="Description" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="post_caption"
              className="buyerrow-form__space--text"
              rules={[
                {
                  required: true,
                  message: 'Add Description!',
                },
              ]}
            >
              <ReactQuillComponent />
            </Form.Item>
          </Flex>
        </Form.Item>

        <Form.Item label="Link" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="link"
              rules={[
                {
                  message: 'Please enter valid url.',
                  pattern:
                    '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
                },
              ]}
            >
              <Input placeholder="http://" style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Flex>
        </Form.Item>

        <Form.Item required label="Synopsys" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="post_synopsys"
              className="buyerrow-form__space--text"
              rules={[
                {
                  required: true,
                  message: 'Add Synopsys!',
                },
              ]}
            >
              <ReactQuillComponent />
            </Form.Item>
          </Flex>
        </Form.Item>

        <Row>
          <PostTags allTags={allTags} setAllTags={setAllTags} />
        </Row>
        <Row className="d-flex jc-end ">
          <div className="d-flex jc-end g-20 ">
            <Button type="primary" ghost onClick={() => setShowForm(false)}>
              Reset
            </Button>
            <Button htmlType="submit" loader={loader} type="primary">
              Create Post
            </Button>
          </div>
        </Row>
      </div>
    </Form>
  );
};

export default ReportPostForm;
